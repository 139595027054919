import React from 'react';
import Layout from '@heureca/shared/components/Layout';
import Section from '@heureca/shared/components/Section';

import * as styles from './studentJobs.module.scss';

const StudentJobs = () => (
  <Layout pageTitle="Student job offer" isSignUp>
    <Section
      name="student"
      title="Job étudiant"
      titleClassName={styles.title}
      containerClassNames={styles.firstContainer}
    >
      <p>
        Chargés d&apos;entrer en contact avec nos clients potentiels par téléphone,
        nos étudiants nous aident à mieux comprendre leurs attentes et à mieux cibler leurs besoins.
        Ils leur posent quelques questions sur leur façon de fonctionner
        afin de voir si leur présenter notre produit est pertinent et,
        ensuite, ils expliquent en quoi ça consiste.
        S&apos;il y a un intérêt, ils passent le flambeau aux
        commerciaux qui s&apos;occupent « du reste ».
      </p>
    </Section>
    <Section
      name="team"
      title="Equipe"
      titleClassName={styles.title}
      containerClassNames={styles.containers}
    >
      <p>
        Comme ils récoltent des infos régulièrement (concurrents, façon de fonctionner, etc.),
        ils nous suggèrent des idées vraiment intéressantes qui nous aident parfois à repenser
        notre stratégie.
        Et certaines de leurs idées ont déjà été mises en pratique
        et se sont révélées très efficaces !
        Bref, les étudiants sont d&apos;une grande importance chez nous et
        font complètement partie de l&apos;équipe.
        Chez Heureca, on avance en tenant compte des idées de chacun.
      </p>
    </Section>
    <Section
      name="searched-profile"
      title="Profil recherché"
      titleClassName={styles.title}
      containerClassNames={styles.containers}
    >
      <ul>
        <li className={styles.li}>Étudiant bachelier à partir de la deuxième année minimum</li>
        <li className={styles.li}>
          Capable de comprendre et de parler l&apos;anglais pour la
          formation et la communication avec les collègues
        </li>
        <li className={styles.li}>Envie d&apos;apprendre, curieux, fiable et sérieux</li>
        <li className={styles.li}>Capable de travailler en toute autonomie</li>
        <li className={styles.li}>
          Ayant un
          {' '}
          <strong>
            intérêt pour le domaine informatique
          </strong>
        </li>
        <li className={styles.li}>Enthousiaste et motivé</li>
        <li className={styles.li}>Créatif, entreprenant et confiant</li>
        <li className={styles.li}><strong>Disponible en semaine entre 9h et 17h</strong></li>
      </ul>
    </Section>
    <Section
      name="the-job"
      title="Que t'apportera ce job ?"
      titleClassName={styles.title}
      containerClassNames={styles.containers}
    >
      <p>
        Tout d&apos;abord, de te dépasser.
        Souvent, les étudiants qui démarrent chez nous ont un peu la boule au ventre,
        inquiets de devoir téléphoner.
        De nos jours, la communication téléphonique est très présente.
        Nous leur apprenons alors à gérer un discours professionnel au téléphone et,
        petit à petit, ils finissent par être très à l&apos;aise.
        Cela leur ouvre le champ des possibilités pour plus tard,
        lorsqu&apos;ils devront trouver un job.
        Ensuite, chez nous, les étudiants ont des responsabilités.
        Nous leur donnons des missions et nous leur faisons confiance quant à leur réalisation.
        Ils sont autonomes et savent ce qu&apos;ils doivent faire et quand.
        La formation aux appels téléphoniques sera assurée
        par un professionnel qui vous accompagnera lors de votre parcours chez Heureca
        Enfin et surtout, nous leur proposons des horaires
        flexibles puisque nous sommes conscients qu&apos;ils doivent composer avec leurs études.
      </p>
    </Section>
    <Section
      name="interested"
      title="Intéressé(e) ?"
      titleClassName={styles.title}
      containerClassNames={styles.containers}
    >
      <p>
        Contacte-nous dès aujourd&apos;hui en nous expliquant tes motivations
        (pourquoi ce job, pourquoi le domaine informatique ?) et envoie-nous ton CV à
        l&apos;adresse
        {' '}
        <strong>
          <a href="mailto:student@heureca.eu">student@heureca.eu</a>
        </strong>
      </p>
      <p>
        Envie de savoir comment ça se passe ?
        Nous sommes aussi transparents sur notre procédure de recrutement.
      </p>
    </Section>
  </Layout>
);

export default StudentJobs;
